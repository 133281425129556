<template>
    <!--- MENU NAVBAR --->
    <nav class="nav-bar">
        <div class="logo">
            <router-link to="/"><img class="logo" src="../assets/logo.jpg" alt="" /></router-link>
        </div>
    </nav>

    <div class="whitespace"></div>

    <div class="matriculas-card">
        <h2>Em manutenção...</h2>
        <a href="https://wa.me/5511972216682" class="botao-matricula">
            <font-awesome-icon :icon="['fab', 'whatsapp']" /> Fale conosco!
        </a>
    </div>

</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "inProgress",
};
</script>

<style scoped>
.whitespace {
    height: 200px !important;
}

h2 {
    display: flex;
    flex-direction: row;
    font-family: "Baloo 2", sans-serif;
    font-weight: 800;
    color: var(--orange);
    font-size: 4rem;
    text-align: center;
}

.matriculas-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 6rem;
}
</style>